<template>
  <div>
    <v-dialog
      v-model="activo"
      width="700"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="">Rechazar Paquete N° {{ id_paquete }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="activo = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4 pb-0">
          <v-textarea
            v-model="motivo"
            dense
            outlined
            label="Motivo de Rechazo"
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="cerrarModal()">Cancelar</v-btn>
          <v-btn color="success" @click="rechazar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ModalRechazarPaquete',
  props: {
    dialogActivo: {
      type: Boolean,
      default: false
    },
    paq_id: {
      type: Number,
      default: 0
    }
  },
  computed: {
    activo: {
      get(){
        return this.dialogActivo
      },
      set(value){
        this.$emit('setearModalRechazo', value)
      }
    },
    id_paquete: {
      get(){
        return this.paq_id
      }
    }
  },
  data() {
    return {
      motivo: ''
    }
  },
  methods: {
    cerrarModal(){
      this.motivo = '';
      this.activo = false;
    },
    rechazar(){
      if (this.motivo.length == 0){
        return this.$store.dispatch('show_snackbar', { text: 'Debe ingresar un motivo de rechazo para continuar.', color: 'info' })
      }
      this.$emit('rechazarPaquete', {rechazar: true, motivo: this.motivo})
      this.cerrarModal()
    }
  },
}
</script>

<style>

</style>