<template>
  <v-dialog
    v-model="activo"
    width="700"
  >
    <v-card>
      <v-card-title class="" height="">
          <!-- <span class="headline">Devolver Paquete</span> -->
        <span class="">Devolver Paquete</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="activo = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-4 pb-0">
        <v-row>
          <v-col cols="12" sm="12">
            <v-autocomplete 
              v-model="selectedBodega" 
              :items="bodegasMovim" 
              item-text="bodega_nombre" 
              label="Bodega destino" 
              dense
              hide-details
              return-object
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12">
            <v-textarea
              v-model="observacion"
              outlined
              dense
              label="Ingrese el motivo de Devolución"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn :color="$vuetify.theme.dark ?  'orange' : 'secondary' " @click="closeModal()">Cancelar</v-btn>
        <v-btn color="success" @click="devolverPaquete()">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModalGenerarDevolucion',
  props: {
    dialogActivo: {
      type: Boolean,
      default: false
    },
    bodegas: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      selectedBodega: {},
      observacion: ''
    }
  },
  computed: {
    activo: {
      get(){
        return this.dialogActivo
      },
      set(value){
        this.$emit('setearModalMovimientos', value)
      }
    },
    bodegasMovim: {
      get(){
        return this.bodegas
      },
    }
  },
  methods: {
    devolverPaquete(){
      if (Object.keys(this.selectedBodega).length == 0){
        return this.$store.dispatch('show_snackbar', { text: 'Debe seleccionar una bodega para poder hacer la devolución.', color: 'info' })
      }
      this.$emit('devolverPaquete', {devolver: true, bodegaDestino: this.selectedBodega.bodega_codigo, observacion: this.observacion})
      this.cerrarModal();
    },
    closeModal(){
      this.$emit('devolverPaquete', {devolver: false, bodegaDestino: null, observacion: ''});
      this.cerrarModal();
    },
    cerrarModal(){  
      this.selectedBodega = {};
      this.observacion    = '';
    }
  },
}
</script>

<style>

</style>