<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <!-- Encabezado -->
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <v-btn
              v-if="!sinRepartidor"
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="info"
              small
              fab
              @click="generarDevoluciones()"
              title="Generar Devolución"
            >
              <v-icon>fas fa-reply</v-icon>
            </v-btn>
            <!-- Titulo Filtros -->
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                <span style="font-size: 24px;">Filtros</span>
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar">
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="6" md="4" class="py-1">
                    Tipo de Envio
                    <v-autocomplete
                      v-model="selectedTipoEnvio"
                      item-text="tipo_descripcion"
                      :items="tiposEnvios"
                      hide-details
                      clearable
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" class="py-1">
                    De Bodega
                    <v-autocomplete
                      v-model="selectedBodegaOrigen"
                      item-text="bodega_nombre"
                      :items="bodegas"
                      hide-details
                      clearable
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" class="py-1">
                    Estado
                    <v-autocomplete
                      v-model="selectedEstado"
                      item-text="estado_nombre"
                      :items="estados"
                      hide-details
                      clearable
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-1">
                    Fecha
                    <FechaPickerRango
                      v-model="fechaRango"
                      :limpiar.sync="limpiarFecha"
                    />
                  </v-col>
                  <v-col cols="12" md="6" class="d-flex justify-end py-1">
                    <BtnFiltroVue
                      :loading="load"
                      clase="mt-0 px-0"
                      @clear="limpiarFiltros()"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Listado de Paquetes -->
        <v-data-table
          :headers="headers"
          :items="paquetes"
          :loading="load"
          dense
          :search="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <!-- Buscador en el datatable -->
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="3">
                <SearchDataTableVue
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <!-- Acciones -->
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom :disabled="sinRepartidor == true">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="primary" @click="visualizar(item)" v-on="on">
                  <v-icon class="mr-2" small>fas fa-eye</v-icon>
                </v-btn>
              </template>
              <span>Ver</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.movimiento_estado_id == 4" :disabled="sinRepartidor == true">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="success" @click="confirmarAcepPaq(item)" v-on="on">
                  <v-icon class="mr-2" small>fas fa-check</v-icon>
                </v-btn>
              </template>
              <span>Aceptar</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.movimiento_estado_id == 4" :disabled="sinRepartidor == true">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="error" @click="rechazarPaq(item)" v-on="on">
                  <v-icon class="mr-2" small>fas fa-times</v-icon>
                </v-btn>
              </template>
              <span>Rechazar</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.movimiento_estado_id == 5" :disabled="sinRepartidor == true">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="success" @click="confirmarEntrega(item)" v-on="on">
                  <v-icon class="mr-2" small>fas fa-biking</v-icon>
                </v-btn>
              </template>
              <span>Entregar</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.movimiento_estado_id == 5" :disabled="sinRepartidor == true">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="error" @click="generarEtiqueta(item)" v-on="on">
                  <v-icon class="mr-2" small>fas fa-file-pdf</v-icon>
                </v-btn>
              </template>
              <span>Generar Etiqueta</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.movimiento_estado_id == 5" :disabled="sinRepartidor == true">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="indigo" @click="openModalDevolver(item)" v-on="on">
                  <v-icon class="mr-2" small>fas fa-reply</v-icon>
                </v-btn>
              </template>
              <span>Generar Devolucion</span>
            </v-tooltip>
          </template>
          <!-- Color para el estado del paquete -->
          <template v-slot:[`item.paquete_estado_nombre`]="{ item }">
            <v-chip small :color="getColorEstadoPaq(item.paquete_estado_id)" dark>{{ item.paquete_estado_nombre }}</v-chip>
          </template>
          <!-- Color para el estado del movimiento -->
          <template v-slot:[`item.movimiento_estado_nombre`]="{ item }">
            <v-chip small :color="getColorEstadoPaq(item.movimiento_estado_id)" dark>{{ item.movimiento_estado_nombre }}</v-chip>
          </template>
          <!-- Fecha -->
          <template v-slot:[`item.fecha`]="{item}">
            {{ moment(item.fecha).format('DD/MM/YYYY') }}
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No hay datos para los filtros seleccionados
            </v-alert>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Modal Confirmar Acción -->
    <ModalConfirmar
      :activoDialog="dialogConf"
      :titulo="tituloConfirmar"
      :texto="textoConfirmar"
      @action="confirmarAccion"
      @setearModalConfAccion="cerrarConfirmar"
    />
    <!-- <ModalConfirmarAccion 
      @ejecutarAccion="confirmarAccion"
    /> -->
    <!-- Modal Ver Paquete -->
    <ModalVerPaquete 
      :ver="dialogVerPaquete"
      :datos="datosPaquete"
      :bodegasDestino="bodegasD"
      :bodegasOrigen="bodegas"
      @closeModalVer="cerrarModalVer"
    />
    <!-- Modal Generar Devolucion un Paquete -->
    <ModalGenerarDevolucion 
      :dialogActivo="dialogMovPaquete"
      :bodegas="bodegasD"
      @setearModalMovimientos="setModalDevolucion"
      @devolverPaquete="confirmarMovimiento"
    />
    <!-- Modal Rechazar Paquete -->
    <ModalRechazarPaquete 
      :dialogActivo="dialogRechazo"
      :paq_id="parseInt(selectedPaquete.paquete_id)"
      @setearModalRechazo="setModalRechazo"
      @rechazarPaquete="confirmarRechazo"
    />
    <!-- Modal Generar varias Devoluciones -->
    <ModalGenerarDevoluciones 
      :dialogActivo="dialogDev"
      :bodegas="bodegasD"
      :repartidor="parseInt(repartidor.repartidor_id)"
      @setearModalDev="setModalDev"
      @confirmDevolver="confirmarDevolucionMasiva"
    />
  </div>
</template>

<script>
import FechaPickerRango from '../../components/util/FechaPickerRango.vue'
import BtnFiltroVue from '../../components/util/BtnFiltro.vue'
import SearchDataTableVue from '../../components/util/SearchDataTable.vue';
import ModalConfirmar from '../../components/util/ModalConfirmar.vue'
import ModalVerPaquete from '../../components/mayorista/paquetes/ModalVerPaquete.vue'
import ModalRechazarPaquete from '../../components/mayorista/repartidores/ModalRechazarPaquete.vue'
import ModalGenerarDevolucion from '../../components/mayorista/repartidores/ModalGenerarDevolucion.vue'
import ModalGenerarDevoluciones from '../../components/mayorista/repartidores/ModalGenerarDevoluciones.vue'
import moment from 'moment'
import download from 'downloadjs'

export default {
  name: 'IndexRepartidor',
  data() {
    return {
      moment:  moment,
      panel: 0,
      selectedTipoEnvio: {},
      tiposEnvios: [],
      selectedBodegaOrigen: {},
      bodegas: [],
      bodegasD: [],
      selectedEstado: {},
      estados: [],
      fechaRango: [null, null],
      limpiarFecha: false,
      load: false,
      headers: [
        { text: 'Id Paquete', align: 'center', value: 'paquete_id' },
        { text: 'Comp. Código', align: 'center', value: 'comp_codigo' },
        { text: 'Fecha', align: 'center', value: 'fecha' },
        { text: 'Destinatario', align: 'center', value: 'destinatario_nombre' },
        { text: 'Estado Paquete', align: 'center', value: 'paquete_estado_nombre' },
        { text: 'Estado Movimiento', align: 'center', value: 'movimiento_estado_nombre' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      paquetes: [],
      search: '',
      provincias: [],
      repartidores: [],
      tienePermiso: 0,
      repartidor: {},
      sinRepartidor: false,
      dialogVerPaquete: false,
      datosPaquete: {
        cliente: '',
        documento: '',
        telefono: '',
        direccion: '',
        localidad: '',
        observacion: '',
        deBodega: {},
        aBodega: {},
        itemsPaquete: []
      },
      selectedPaquete: {},
      bodegaDevolucion: 0,
      opcion: 0,
      dialogMovPaquete: false,
      dialogRechazo: false,
      dialogDev: false,
      paquetesDevolver: [],
      bodegaDevolucionMasiva: 0,
      obsDevolucion: '',
      sortBy: 'paquete_id',
      sortDesc: true,
      dialogConf: false,
      tituloConfirmar: '',
      textoConfirmar: '',
      pantallaChica: this.$vuetify.breakpoint.xs,
    }
  },
  components: {
    FechaPickerRango,
    SearchDataTableVue,
    BtnFiltroVue,
    ModalConfirmar,
    ModalVerPaquete,
    ModalRechazarPaquete,
    ModalGenerarDevolucion,
    ModalGenerarDevoluciones
  },
  created(){
    this.initFormRepartidor()
  },
  methods: {
    async initFormRepartidor(){
      this.$store.state.loading = true;
      let initPeticion          = await this.$store.dispatch('paquetes/initFormPaquetes')
      if (initPeticion.resultado == 1){
        this.tiposEnvios  = initPeticion.tipos
        this.estados      = initPeticion.estados
        this.bodegas      = initPeticion.bodegasO
        this.bodegasD     = initPeticion.bodegasD
        this.provincias   = initPeticion.provincias
        this.repartidores = initPeticion.repartidores
        this.tienePermiso = initPeticion.permiso[0].tienePermiso
        this.getRepartidor()
      }else{
        this.$store.state.loading = false;
        this.sinRepartidor = true
        this.tiposEnvios   = []
        this.estados       = []
        this.bodegas       = []
        this.bodegasD      = []
        this.provincias    = []
        this.repartidores  = []
        return this.$store.dispatch('show_snackbar', { text: initPeticion.message, color: 'error' })
      }
    },
    async getRepartidor(){
      let repartidorPeticion    = await this.$store.dispatch('repartidores/getRepartidor')
      this.$store.state.loading = false;
      if (repartidorPeticion.resultado == 1){
        if(repartidorPeticion.repartidor.length != 0){
          this.repartidor = repartidorPeticion.repartidor[0]
        }else{
          this.sinRepartidor = true;
        }
      }else{
        this.repartidor = {}
        this.sinRepartidor = true
        this.$store.dispatch('show_snackbar', { text: repartidorPeticion.msj, color: 'error' })
      }
    },
    getColorEstadoPaq(estado_id){
      if (estado_id == 2) return 'green'
      else if (estado_id == 3 ) return 'red'
      else return 'blue'
    },
    async buscar(){
      if(!this.sinRepartidor){
        if(this.fechaRango[0] && this.fechaRango.length == 2){
          // asigno fechas
          let fechaDesde = moment(this.fechaRango[0]).format('DD/MM/YYYY')
          let fechaHasta = ''
          if (this.fechaRango[1].length > 0){
            fechaHasta = moment(this.fechaRango[1]).format('DD/MM/YYYY')
          }
          
          // tipo de envio
          let tipoEnvio = 0
          if (this.selectedTipoEnvio && Object.keys(this.selectedTipoEnvio).length > 0){
            tipoEnvio = this.selectedTipoEnvio.tipo_id
          }
          
          // bodega origen
          let deBodega = 0
          if (this.selectedBodegaOrigen && Object.keys(this.selectedBodegaOrigen).length > 0){
            deBodega = this.selectedBodegaOrigen.bodega_codigo
          }
          
          // estado de paquete
          let estadoPquete = []
          if(this.selectedEstado && Object.keys(this.selectedEstado).length > 0){
            estadoPquete.push(this.selectedEstado.estado_id)
          }
          
          // estado de movimiento
          let estadoMov = []
          this.paquetes = []
          
          // body para la api
          let bodyBusqueda = {
            fechaDesde:    fechaDesde,
            fechaHasta:    fechaHasta,
            tipoEnvio:     tipoEnvio,
            deBodega:      deBodega,
            estadoPquete:  estadoPquete,
            usuario:       this.$store.state.username,
            estadoMov:     estadoMov,
            repartidor_id: this.repartidor.repartidor_id
          }
          this.load = true
          let paquetesPeticion = await this.$store.dispatch('paquetes/getPaquetes', bodyBusqueda)
          this.load = false
          if (paquetesPeticion.resultado == 1){
            this.paquetes = paquetesPeticion.paquetes;
            for(let i in this.paquetes){
              let [ dia, mes, anio ] = this.paquetes[i].fecha.split('/');
              this.paquetes[i].fecha = new Date(anio,mes-1,dia);
            }
          }else{
            this.paquetes = []
            this.$store.dispatch('show_snackbar', { text: paquetesPeticion.message, color: 'error' })
          }
        }
      }else{
        this.$store.dispatch('show_snackbar', { text: 'Este usuario no tiene asociado un repartidor', color: 'info' })
      }
    },
    limpiarFiltros(){
      this.selectedTipoEnvio    = {}
      this.selectedBodegaOrigen = {}
      this.selectedEstado       = {}
      this.limpiarFecha         = true
    },
    //ver
    async visualizar(item){
      this.datosPaquete.cliente     = item.destinatario_nombre
      this.datosPaquete.documento   = item.destinatario_nro_doc
      this.datosPaquete.telefono    = item.destinatario_telefono
      this.datosPaquete.direccion   = item.destinatario_domicilio
      this.datosPaquete.localidad   = item.destinatario_localidad
      this.datosPaquete.observacion = item.observacion

      let bodegaOrig = this.bodegas.filter(element => element.bodega_codigo == item.bodega_origen) // obtengo bodega origen
      if (bodegaOrig.length > 0){
        this.datosPaquete.deBodega = bodegaOrig[0]
      }
      
      let bodegaDes = this.bodegasD.filter(element => element.bodega_codigo == item.bodega_destino) // obtengo la bodega destino
      if (bodegaDes.length > 0){
        this.datosPaquete.aBodega = bodegaDes[0]
      }
      this.datosPaquete.itemsPaquete = []
      this.$store.state.loading      = true;
      let detallesPeticion           = await this.$store.dispatch('paquetes/getDetallesPaquete', {paquete_id: item.paquete_id})
      this.$store.state.loading      = false;
      if (detallesPeticion.resultado != 1){
        return this.$store.dispatch('show_snackbar', { text: detallesPeticion.msj, color: 'error' }) // mostrar el error y abrir el modal
      }
      this.datosPaquete.itemsPaquete = detallesPeticion.paquetes
      this.dialogVerPaquete          = true
    },
    cerrarModalVer(value){
      this.dialogVerPaquete = value
    },
    //confirmar acciones
    confirmarAccion(value){
      if (value){
        if (this.opcion == 1){
          this.aceptarPaquete();
        }
        if (this.opcion == 2){
          this.generarMovimiento();
        }
        if (this.opcion == 3){
          this.entregarPaquete();
        }
        if (this.opcion == 4){
          this.rechazarPaquete();
        }
        if (this.opcion == 5){
          this.devolucionMasiva();
        }
      }else{
        this.motivoRechazo          = '';
        this.paquetesDevolver       = [];
        this.bodegaDevolucionMasiva = 0;
      }
    },
    cerrarConfirmar(value){
      this.dialogConf = value;
    },
    //Aceptar Paquete
    confirmarAcepPaq(item){
      this.opcion = 1;
      this.selectedPaquete = item; //guardo el dato
      this.tituloConfirmar = 'Aceptar Paquete';
      this.textoConfirmar  = 'Está aceptando el paquete N°' + item.paquete_id + '. ¿Confirma esta acción?';
      this.dialogConf      = true;
    },
    async aceptarPaquete(){
      let body = { // armo el body para la peticion
        repartidor_id: this.repartidor.repartidor_id,
        paquete_id: this.selectedPaquete.paquete_id
      }
      this.$store.state.loading = true;
      let aceptarPeticion       = await this.$store.dispatch('repartidores/aceptarPaquete', body)
      this.$store.state.loading = false;
      if (aceptarPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', { text: 'El paquete fue aceptado exitosamente.', color: 'success' })
        setTimeout(() => [this.buscar()], 2000)
      }else{
        this.$store.dispatch('show_snackbar', { text: aceptarPeticion.msj, color: 'error' })
      }
    },
    //Generar Devolucion (sólo 1)
    openModalDevolver(item){
      this.opcion = 2
      this.selectedPaquete = item //guardo el dato
      this.dialogMovPaquete = true
    },
    confirmarMovimiento(value){
      if (value.devolver == true){
        this.bodegaDevolucion = value.bodegaDestino;
        this.obsDevolucion    = value.observacion;
        this.tituloConfirmar  = 'Devolver Paquete';
        this.textoConfirmar   = 'Está devolviendo el paquete N°' + this.selectedPaquete.paquete_id + '. ¿Confirma esta acción?';
        this.dialogConf       = true;
      }else{
        this.dialogMovPaquete = false;
        this.bodegaDevolucion = 0;
        this.obsDevolucion    = '';
      }
    },
    async generarMovimiento(){
      // cierro el modal de devolucion
      this.dialogMovPaquete = false
      let body = { // armo el body a enviar
        bodega_origen:  this.selectedPaquete.bodega_actual_codigo,
        bodega_destino: this.bodegaDevolucion,
        observacion:    this.obsDevolucion,
        paquete_id:     this.selectedPaquete.paquete_id,
        repartidor_id:  this.repartidor.repartidor_id
      }
      this.$store.state.loading = true;
      let devolucionPeticion    = await this.$store.dispatch('repartidores/generarDevolucion', body)
      this.$store.state.loading = false;
      if (devolucionPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', { text: 'El paquete fue devuelto exitosamente.', color: 'success' })
        setTimeout(() => [this.buscar()], 2000)
      }else{
        this.$store.dispatch('show_snackbar', { text: devolucionPeticion.msj, color: 'error' })
      }
    },
    setModalDevolucion(value){
      this.dialogMovPaquete = value
    },
    //Entrega de paquete
    async confirmarEntrega(item){
      this.opcion = 3
      this.selectedPaquete = item //guardo el dato
      if (item.paquete_tipo_id == 4){
        this.$store.state.loading = true;
        let saldosPeticion        = await this.$store.dispatch('repartidores/validarCtaCorriente', {paquete_id: item.paquete_id})
        this.$store.state.loading = false;
        if (saldosPeticion.resultado == 1){
          if (parseInt(saldosPeticion.saldos[0].saldo) <= 0){
            this.tituloConfirmar = 'Entregar Paquete';
            this.textoConfirmar  = 'Está entregando el paquete N°' + item.paquete_id + '. ¿Confirma esta acción?';
            this.dialogConf      = true;
          }
          else if ((parseInt(saldosPeticion.saldos[0].limite_credito) - parseInt(saldosPeticion.saldos[0].saldo) + 
              parseInt(saldosPeticion.saldos[0].total_posterior)) > 0){
            this.tituloConfirmar = 'Entregar Paquete';
            this.textoConfirmar  = 'Está entregando el paquete N°' + item.paquete_id + '. ¿Confirma esta acción?';
            this.dialogConf      = true;
          }else{
            return this.$store.dispatch('show_snackbar', { text: 'El Saldo Actual del Cliente ($' + saldosPeticion.saldos[0].saldo + ') supera su límite de crédito ($' + saldosPeticion.saldos[0].limite_credito + '). No se puede registrar la entrega del paquete.', color: 'info' })
          }
        }else{
          this.$store.dispatch('show_snackbar', { text: saldosPeticion.msj, color: 'error' })
        }
      }else{
        this.tituloConfirmar = 'Entregar Paquete';
        this.textoConfirmar  = 'Está entregando el paquete N°' + item.paquete_id + '. ¿Confirma esta acción?';
        this.dialogConf      = true;
      }
    },
    async entregarPaquete(){
      this.$store.state.loading = true;
      let entregaPeticion       = await this.$store.dispatch('repartidores/entregarPaquete', {paquete_id: this.selectedPaquete.paquete_id})
      this.$store.state.loading = false;
      if (entregaPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', { text: 'El paquete fue entregado exitosamente.', color: 'success' })
        setTimeout(() => [this.buscar()], 2000)
      }else{
        this.$store.dispatch('show_snackbar', { text: entregaPeticion.msj, color: 'error' })
      }
    },
    //Rechazar Paquete
    rechazarPaq(item){
      this.opcion = 4
      this.selectedPaquete = item //guardo el dato
      this.dialogRechazo   = true
    },
    confirmarRechazo(value){
      if (value.rechazar == true){
        this.motivoRechazo   = value.motivo
        this.tituloConfirmar = 'Rechazar Paquete';
        this.textoConfirmar  = 'Está rechazando el paquete N°' + this.selectedPaquete.paquete_id + '. ¿Confirma esta acción?';
        this.dialogConf      = true;
      }
    },
    async rechazarPaquete(){
      let body = { // armo el body
        paquete_id:    this.selectedPaquete.paquete_id,
        motivo:        this.motivoRechazo,
        repartidor_id: this.repartidor.repartidor_id
      }
      this.$store.state.loading = true;
      let rechazoPeticion       = await this.$store.dispatch('repartidores/rechazarPaquete', body)
      this.$store.state.loading = false;
      if (rechazoPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', { text: 'El paquete fue rechazado exitosamente.', color: 'success' })
        setTimeout(() => [this.buscar()], 2000)
      }else{
        this.$store.dispatch('show_snackbar', { text: rechazoPeticion.msj, color: 'error' })
      }
    },
    setModalRechazo(value){
      this.dialogRechazo = value
    },
    //Generar Devoluciones
    generarDevoluciones(){
      this.opcion = 5
      this.dialogDev = true
    },
    confirmarDevolucionMasiva(value){
      this.paquetesDevolver       = value.paquetes;
      this.bodegaDevolucionMasiva = value.bodegaDestino;
      this.tituloConfirmar        = 'Generar Devolución Masiva';
      this.textoConfirmar         = 'Se procederá a devolver todos los paquetes del listado. ¿Confirma esta acción?';
      this.dialogConf             = true;
    },
    async devolucionMasiva(){
      let body = { // armo el body
        paquetes:      this.paquetesDevolver,
        repartidor_id: this.repartidor.repartidor_id,
        bodegaDestino: this.bodegaDevolucionMasiva
      }
      this.$store.state.loading = true;
      let devolverPeticion      = await this.$store.dispatch('repartidores/devolucionMasiva', body)
      this.$store.state.loading = false;
      if (devolverPeticion.resultado != 1){
        return this.$store.dispatch('show_snackbar', { text: devolverPeticion.msj, color: 'error' })
      }
      this.$store.dispatch('show_snackbar', { text: 'Los paquetes fueron devueltos a la bodega indicada correctamente.', color: 'success' })
    },
    setModalDev(value){
      this.dialogDev = value
    },
    // Generar Etiquetas
    async generarEtiqueta(item){
      let body = { // armo mi body para la API
        destinatario: item.destinatario_nombre,
        documento:    item.destinatario_nro_doc,
        domicilio:    item.destinatario_domicilio,
        localidad:    item.destinatario_localidad,
        telefono:     item.destinatario_telefono,
        paquete_id:   item.paquete_id
      }
      this.$store.state.loading = true;
      let etiquetaPedido        = await this.$store.dispatch('paquetes/generateLabel', body)
      this.$store.state.loading = false;
      if (etiquetaPedido.resultado != 1){
        return this.$store.dispatch('show_snackbar', { text: etiquetaPedido.msj, color: 'error' })
      }
      download(etiquetaPedido.archivo, 'Etiqueta.pdf', 'application/pdf')
    },
  },
  watch: {
    paquetes: function(){
      if (this.paquetes.length > 0){
        for (let id in this.paquetes){
          this.paquetes[id].fecha_log = moment(this.paquetes[id].fecha_log).format('DD/MM/YYYY')
        }
      }
    },
    dialogVerPaquete: function(){
      if (this.dialogVerPaquete == false){
        this.datosPaquete.cliente       = '';
        this.datosPaquete.documento     = '';
        this.datosPaquete.telefono      = '';
        this.datosPaquete.direccion     = '';
        this.datosPaquete.localidad     = '';
        this.datosPaquete.observacion   = '';
        this.datosPaquete.deBodega      = '';
        this.datosPaquete.aBodega       = '';
        this.datosPaquete.itemsPaquete  = [];
      }
    },
    '$vuetify.breakpoint.xs'(val){
      this.pantallaChica = val;
    },
  },
}
</script>

<style>

</style>